import { useState, useEffect } from 'react'
import { Box, Container } from '@mui/material'
import SideBar from './SideBar';
import Appbar from './TopBar'
import { useNavigate, useParams } from 'react-router-dom';
import Circular from '../../Global/Circular';
import AddBook from '../Book/AddBook';
import AllBooks from '../Book/AllBooks';
const Dashboard = () => {
    const { Selected } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const allSections = ['allBook', 'addBook'];
    useEffect(() => {
        if (Selected) {
            if (!allSections.includes(Selected)) {
                navigate('/404')
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        }
    }, [])
    return (
        <>
            {
                !loading &&
                <Box sx={{ display: "flex" }}>
                    <SideBar selected={Selected || 'AllBook'} />
                    <Box sx={{ width: '100%' }}>
                        <Appbar />
                        {
                            Selected === 'allBook' && <AllBooks />
                        }
                        {
                            Selected === 'addBook' && <AddBook />
                        }
                    </Box>
                </Box>
            }
            {
                loading && <Circular />
            }
        </>
    )
}

export default Dashboard