import { useState, useRef } from 'react'
import { Button, Container, TextField, Box, useTheme, useMediaQuery, IconButton } from '@mui/material'
import Plan from './Plan';
import { PropsAlert, Type, bookInfos } from '../../Type/props';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileFree from './FileFree';
import FilePaid from './FilePaid';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Alerte from '../../Global/Alerte';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { addDoc, collection, setDoc } from 'firebase/firestore';
import { baseURL2 } from '../Register/Login';
import Circular from '../../Global/Circular';
const AddBook = () => {
    const ImageRef = useRef(null);
    const navigator = useNavigate();
    const [image, setImage] = useState<string>('');
    const [type, setType] = useState<Type>('');
    const [isAdded, setIsAdded] = useState<boolean>(false);
    const [bookInfos, setBookInfos] = useState<bookInfos>({} as bookInfos);
    const [alerting, setAlerting] = useState<PropsAlert>({} as PropsAlert);
    const [loading, setLoading] = useState<boolean>(false);

    const validateInputs = () => {
        if (!bookInfos.description) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add description' } });
            return false;
        }
        if (!bookInfos.title) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add title' } });
            return false;
        }
        if (!bookInfos.index) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add index' } });
            return false;
        }

        if (!bookInfos.passwordUrl) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add password' } });
            return false;
        }

        if (!bookInfos.price) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add price' } });
            return false;
        }
        if (!image) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add image' } });
            return false;
        }
        if (type === 'free' && !bookInfos.pdfRUrl) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add free pdf' } });
            return false;
        }
        if (type === 'paid' && !(bookInfos.pdfUrl && bookInfos.pdfRUrl)) {
            setAlerting({ open: true, alerting: { type: 'error', text: 'please add pdf and pdfR' } });
            return false;
        }
        return true;

    };
    const handleAdd = async (e: any) => {
        e.preventDefault()
        await setAlerting({} as PropsAlert);
        setLoading(true)
        if (!validateInputs()) {
            return;
        }
        const formData = new FormData();
        // if (bookInfos.title && bookInfos.description && bookInfos.price && bookInfos.index) {
        //     formData.append('title', bookInfos.title);
        //     formData.append('price', bookInfos.price.toString());
        //     formData.append('description', bookInfos.description);
        //     formData.append('index', bookInfos.index.toString());
        // }


        if (bookInfos.pdfUrl) {
            formData.append('pdfUrl', bookInfos.pdfUrl);
        }
        if (bookInfos.pdfRUrl) {
            formData.append('pdfRUrl', bookInfos.pdfRUrl);
        }
        if (bookInfos.image) {
            formData.append('image', bookInfos.image);
        }
        formData.append('isFree', type === 'free' ? 'true' : 'false')
        console.log("start ");
        console.log(baseURL2);

        var isUpload = await axios.post(`${baseURL2}/api.php/addBook`, formData).then(async res => {
            const { success, message } = res.data;
            console.log(success, message);
            return !!success;
        }).catch(err => {
            console.log(err);
            return false;
        });

        if (!isUpload) {
            setLoading(false);
            setAlerting({ open: true, alerting: { type: 'error', text: 'An error occurred.' } });
            return;
        }

        const d = await addDoc(collection(db, "app/data/book"), {
            "title": bookInfos.title,
            "description": bookInfos.description,
            "isFree": type === 'free',
            "passwordUrl": bookInfos.passwordUrl,
            "image": `${baseURL2}/${(bookInfos.image as File).name}`,
            ...(bookInfos.pdfUrl && { "pdfUrl": `${baseURL2}/${(bookInfos.pdfUrl as File).name}` }),
            "pdfRUrl": `${baseURL2}/${(bookInfos.pdfRUrl as File).name}`,
            "index": bookInfos.index,
            "price": bookInfos.price,
        }).catch(err => {
            console.log(err);
            return false;
        });
        if (!d) {
            setLoading(false);
            setAlerting({ open: true, alerting: { type: 'error', text: 'An error occurred.' } });
            return;
        }

        console.log(d);
    
        setLoading(false);

         navigator("/")
        setAlerting({ open: true, alerting: { type:  'success' , text: "" } })


        return;
        axios.post(`/addBook`, formData).then(async res => {

            const { success, message } = res.data
            console.log(success, message);
            if (res.status === 200) {

                const d = await addDoc(collection(db, "app/data/book"), {
                    ...message
                    , "index": bookInfos.index,
                    "price": bookInfos.price,
                });
                console.log(d);

                navigator("/")
                setAlerting({ open: true, alerting: { type: success ? 'success' : 'error', text: message } })

            }
        })
            .catch(err => {
                console.log(err);
            });
    }
    const onLoadImage = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            console.log(files[0]);

            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });
            console.log(modifiedFile);


            setImage(blob)
            setBookInfos({ ...bookInfos, image: modifiedFile })
        }
        event.target.value = '';
    };
    const theme = useTheme();
    const isMatchedPhone = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <>
            {
                !isAdded &&
                <Container sx={{ my: 3, height: "70vh", display: "flex", flexDirection: "column", rowGap: 5, justifyContent: "center", alignItems: "center" }}>
                    <Plan type={type} setType={setType} />
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={type ? false : true}
                        startIcon={<AddBoxIcon />}
                        size='large'

                        onClick={() => setIsAdded(true)}
                    >
                        Add book
                    </Button>
                </Container >
            }
            {
                isAdded &&
                <>
                    <Container
                        component='form'
                        onSubmit={handleAdd}
                        sx={{ my: 5, display: "flex", flexWrap: 'wrap', gap: 3 }}
                    >
                        <Box
                            sx={{ flex: 1, display: "grid", gap: 2 }}
                        >
                            <TextField
                                type='text'
                                label='Enter the title'
                                variant='outlined'
                                color='primary'
                                value={bookInfos.title || ''}
                                onChange={(e) => setBookInfos({ ...bookInfos, title: e.target.value })}
                            />
                            <TextField
                                type='number'
                                label='Enter the price (DA)'
                                variant='outlined'
                                color='primary'
                                value={bookInfos.price < 0 ? 0 : bookInfos.price}
                                onChange={(e) => setBookInfos({ ...bookInfos, price: Number(e.target.value) })}
                            />
                            <TextField
                                type='number'
                                label='Enter the index'
                                variant='outlined'
                                color='primary'
                                value={bookInfos.index < 0 ? 0 : bookInfos.index}
                                onChange={(e) => setBookInfos({ ...bookInfos, index: Number(e.target.value) })}
                            />
                            <TextField
                                label='Enter the password'
                                variant='outlined'
                                color='primary'
                                value={bookInfos.passwordUrl}
                                onChange={(e) => setBookInfos({ ...bookInfos, passwordUrl: e.target.value })}
                            />
                            {
                                type === 'free' && <FileFree bookInfos={bookInfos} setBookInfos={setBookInfos} />
                            }
                            {
                                type === 'paid' && <FilePaid bookInfos={bookInfos} setBookInfos={setBookInfos} />
                            }
                            <TextField
                                type='text'
                                label='Enter the description'
                                variant='outlined'
                                color='primary'
                                value={bookInfos.description || ''}
                                onChange={(e) => setBookInfos({ ...bookInfos, description: e.target.value })}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: "center", width: '220px', aspectRatio: 1 / 1.41, bgcolor: 'background.paper', borderRadius: '10px', p: 1, border: `solid 1.5px ${theme.palette.primary.main}` }}>
                                {
                                    !image ? <Button
                                        variant='contained'
                                        component='label'
                                        size={isMatchedPhone ? 'medium' : 'large'}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        upload your image
                                        <input
                                            ref={ImageRef}
                                            type="file"
                                            name='image'
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={onLoadImage}
                                        />
                                    </Button>
                                        :
                                        <>
                                            <IconButton
                                                size='small'
                                                sx={{ position: "absolute", top: '-15px', right: '-15px', bgcolor: "error.main", color: "white", '&:hover': { bgcolor: "error.main" } }}
                                                onClick={() => setImage('')}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                            <img src={image} alt="custom card" loading='lazy' style={{ width: '100%', height: '100%', borderRadius: '10px' }} />
                                        </>
                                }

                            </Box>
                        </Box>
                        <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", my: 3 }}>
                            <Button
                                type='submit'
                                variant='contained'
                                size='large'
                                startIcon={<AddBoxIcon />}
                            >
                                Add your book
                            </Button>
                        </Box>
                    </Container>

            
                            </>

        }
            <Alerte open={alerting.open} alerting={alerting.alerting} />
            {
                loading && <Circular />
            }
        </>

    )
}

export default AddBook