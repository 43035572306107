import React, { useState, useRef } from 'react'
import { Button, useTheme, useMediaQuery, Box, Typography } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { bookInfos } from '../../Type/props';
import { v4 as uuidv4 } from 'uuid';

const FileFree = (props: { bookInfos: bookInfos, setBookInfos: React.Dispatch<React.SetStateAction<bookInfos>> }) => {
    const { bookInfos, setBookInfos } = props
    const [File1, setFile] = useState<any>(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const inputFileRef = useRef(null);
    const onLoadFile = (event: any) => {
        
        const { files } = event.target;
        console.log(files);
        
        if (files && files[0]) {
            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}` ;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });


            setFile(modifiedFile)
            setBookInfos({ ...bookInfos, pdfRUrl: modifiedFile })
            setSelectedFileName(files[0].name)
        }
        event.target.value = '';
    };
    const theme = useTheme();
    const isMatchedPhone = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ display: "flex",alignItems:'center' }}>
                <Button
                    variant='outlined'
                    component='label'
                    size={isMatchedPhone ? 'medium' : 'large'}
                    startIcon={<UploadFileIcon />}
                >
                    Upload your free book
                    <input
                        ref={inputFileRef}
                        type="file"
                        name='pdfUrl'
                        style={{ display: 'none' }}
                        accept="application/*"
                        onChange={onLoadFile}
                    />
                </Button>
                {
                    selectedFileName &&
                    <Typography variant='body2' color='primary' fontWeight='bold' mx={1}>
                        {selectedFileName}
                    </Typography>
                }
            </Box>
        </>
    )
}

export default FileFree