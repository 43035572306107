import { Outlet, Navigate } from 'react-router-dom'
import { refreshToken } from '../config_global'
import Cookies from 'universal-cookie'
const PrivateRegister = () => {
    let auth = {
        token: new Cookies().get('refreshToken'),
    }
    return (
        auth.token ? <Outlet /> : <Navigate to='/login' />
    )
}

export default PrivateRegister