import { useState, useRef, useEffect } from 'react'
import { Box, Button, Container, IconButton, TextField, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate, useParams } from 'react-router-dom'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { v4 as uuidv4 } from 'uuid';

import DeleteIcon from '@mui/icons-material/Delete';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SaveIcon from '@mui/icons-material/Save';
import { PropsAlert, bookInfos, paidbookprops } from '../../Type/props';
import axios from 'axios';
import Alerte from '../../Global/Alerte';
import Circular from '../../Global/Circular';
import { db } from './firebase';
import { DocumentSnapshot, deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { baseURL2 } from '../Register/Login';

const ModifyBook = () => {
    const { id } = useParams();
    const navigator = useNavigate()
    const [loading, setLoading] = useState<boolean>(true);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const ImageRef = useRef(null);
    const inputFreeRef = useRef(null);
    const inputPaidRef = useRef(null);
    const inputSampRef = useRef(null);
    const [alerting, setAlerting] = useState<PropsAlert>({} as PropsAlert);
    const [book, setBook] = useState<bookInfos>({} as bookInfos);
    const [image, setImage] = useState<string>('');
    const [deleteIt, setDeletedId] = useState<boolean>(false);
    useEffect(() => {
        const getOneBook = async () => {
            //using firebase 
            const doc1 = await getDoc(doc(db, `app/data/book/${id}`)).catch(err => {
                console.log(err);
                return false;
            });
            if (!doc1) {
                return;
            }
            const book1 = (doc1 as DocumentSnapshot).data();
            setTimeout(() => {
                setBook(() => book1 as bookInfos);
                setImage(`${(book1 as bookInfos).image}`);
                setLoading(false)
            }, 1000);
        }
        getOneBook()
    }, [])

    const [selectedFileName, setSelectedFileName] = useState<paidbookprops>({} as paidbookprops);
    const onLoadFile = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });
            setBook({ ...book, pdfUrl: modifiedFile })
            setSelectedFileName({ ...selectedFileName, pdfUrl: files[0].name })
        }
        event.target.value = '';
    };
    const onLoadSample = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });
            setBook({ ...book, pdfRUrl: modifiedFile })
            setSelectedFileName({ ...selectedFileName, pdfRUrl: files[0].name })
        }
        event.target.value = '';
    };
    const onLoadImage = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            console.log(files[0]);

            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });
            console.log(modifiedFile);


            setImage(blob)
            setBook({ ...book, image: modifiedFile })
        }
        event.target.value = '';
    };
    const handleDelete = async () => {
        console.log('ok');
        const docRef = doc(db, `app/data/book/${id}`);
        //delete book definifly
        deleteDoc(docRef).then(() => {
            setAlerting({ open: true, alerting: { type: 'success', text: 'The book is deleted successfully.' } });
            navigator('/Dashboard/allBook');
        }).catch(err => {
            setAlerting({ open: true, alerting: { type: 'error', text: err.message } });
        }
        );

    }

    const handleSave = async (e: any) => {
        e.preventDefault()
        setIsModified(true);
        const formData = new FormData();

        var data = {} as bookInfos;
        if (!!book.description) {
            data['description'] = book.description;
        }
        if (!!book.title) {
            data['title'] = book.title;
        }
        if (!!book.index) {
            data['index'] = book.index;
        }
        if (image !== "") {
            formData.append('image', book.image)
        }
        if (!!book.price) {
            data['price'] = book.price;
        }
        if ((!!selectedFileName.pdfRUrl)) {
            console.log((book.pdfRUrl));

            formData.append('pdfRUrl', book.pdfRUrl)
            console.log(formData);

        }
        if (!!selectedFileName.pdfUrl) {
            formData.append('pdfUrl', book.pdfUrl)
        }

        if (!!book.passwordUrl) {
            data['passwordUrl'] = book.passwordUrl;
        }
        console.log(!!selectedFileName.pdfRUrl);



        if (
            (!!selectedFileName.pdfUrl) ||
            (!!selectedFileName.pdfRUrl)
            || (!!image)

        ) {
            var isUpload = await axios.post(`${baseURL2}/api.php/addBook`, formData).then(async res => {
                const { success, message } = res.data;
                console.log(success, message);
                return !!success;
            }).catch(err => {
                console.log(err);
                return false;
            });

            if (!isUpload) {
                setIsModified(false);
                setAlerting({ open: true, alerting: { type: 'error', text: 'An error occurred.' } });
                return;
            }

        }

        // update to the database by firebase
        const docRef = doc(db, `app/data/book/${id}`);

        await updateDoc(docRef, {
            ...data,
            ...(image !== "" && { image: `${baseURL2}/${(book.image as File).name}` }),
            ...(selectedFileName.pdfUrl && { pdfUrl: `${baseURL2}/${(book.pdfUrl as File).name}` }),
            ...(selectedFileName.pdfRUrl && { pdfRUrl: `${baseURL2}/${(book.pdfRUrl as File).name}` }),

        }).then(() => {
            setIsModified(false);
            setAlerting({ open: true, alerting: { type: 'success', text: 'The book is modified successfully.' } });
        }).catch(err => {
            setIsModified(false);
            setAlerting({ open: true, alerting: { type: 'error', text: err.message } });
        });


        return;
    }
    const theme = useTheme();
    return (
        <>
            {
                !loading &&
                <Container
                    component='section'
                    sx={{ minHeight: "100vh", position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Typography
                            data-aos='zoom-in'
                            variant='h5'
                            color='whitesmoke'
                            sx={{
                                position: 'relative',
                                fontWeight: 'bold',
                                bgcolor: "primary.main",
                                border: `2.3px solid ${theme.palette.primary.main}`,
                                padding: '5px',
                                borderRadius: '15px',
                                '&::before': {
                                    bgcolor: theme.palette.primary.main, position: 'absolute', content: '" "', height: '10px', width: '10px', borderRadius: '50%', bottom: '40%', left: '-25px',
                                },
                                '&::after': {
                                    bgcolor: theme.palette.primary.main, position: 'absolute', content: '" "', height: '10px', width: '10px', borderRadius: '50%', bottom: '40%', right: '-25px',
                                }
                            }}
                        >
                            <i>Modify book's infos</i>
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2, p: 3, bgcolor: "background.paper", display: 'grid', rowGap: 2, width: { xs: '100%', md: "70%", lg: '50%' }, borderRadius: '12px' }}>
                        <TextField
                            type='text'
                            label='title of the book'
                            variant='outlined'
                            color='primary'
                            value={book.title}
                            onChange={(e) => setBook({ ...book, title: e.target.value })}
                        />
                        <TextField
                            type='number'
                            label='price of the book'
                            variant='outlined'
                            color='primary'
                            value={book.price}
                            onChange={(e) => setBook({ ...book, price: Number(e.target.value) })}
                        />
                        <TextField
                            type='number'
                            label='Enter the index'
                            variant='outlined'
                            color='primary'
                            value={book.index}
                            onChange={(e) => setBook({ ...book, index: Number(e.target.value) })}
                        />
                        <TextField
                            label='Enter the password'
                            variant='outlined'
                            color='primary'
                            value={book.passwordUrl}
                            onChange={(e) => setBook({ ...book, passwordUrl: e.target.value })}
                        />
                        <TextField
                            type='text'
                            label='description of the book'
                            variant='outlined'
                            color='primary'
                            fullWidth
                            multiline
                            rows={3}
                            value={book.description}
                            onChange={(e) => setBook({ ...book, description: e.target.value })}
                        />
                        <Box sx={{ display: { xs: 'grid', md: 'flex' } }}>
                            <Box sx={{ flexGrow: 1, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: "center", my: 2 }}>
                                {
                                    image ?
                                        <img src={image} width='200px' style={{ aspectRatio: 1 / 1.41, borderRadius: '12px' }} loading='lazy' />
                                        :
                                        <Box
                                            sx={{ width: '200px', aspectRatio: 1 / 1.41, display: "flex", justifyContent: 'center', alignItems: "center", borderRadius: '12px', border: `2px solid ${theme.palette.primary.main}` }}
                                        >
                                            <Button
                                                variant='contained'
                                                component='label'
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                upload your image
                                                <input
                                                    ref={ImageRef}
                                                    type="file"
                                                    name='image'
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={onLoadImage}
                                                />
                                            </Button>
                                        </Box>
                                }
                                <IconButton
                                    size='small'
                                    sx={{ position: "absolute", top: '-15px', right: '43%', bgcolor: "error.main", color: "white", '&:hover': { bgcolor: "error.main" } }}
                                    onClick={() => setImage('')}
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            </Box>
                            {
                                !book.isFree ?
                                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: 'column', justifyContent: "center", gap: 2, alignItems: "center" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Button
                                                variant='outlined'
                                                component='label'
                                                startIcon={<UploadFileIcon />}
                                            >
                                                Upload your sample book
                                                <input
                                                    ref={inputSampRef}
                                                    type="file"
                                                    name='pdfRUrl'
                                                    style={{ display: 'none' }}
                                                    accept="application/*"
                                                    onChange={onLoadSample}
                                                />
                                            </Button>
                                            {
                                                selectedFileName.pdfRUrl &&
                                                <Typography variant='body2' color='primary' mx={1} fontWeight='bold'>
                                                    {selectedFileName.pdfRUrl}
                                                </Typography>
                                            }
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Button
                                                variant='outlined'
                                                component='label'
                                                startIcon={<UploadFileIcon />}
                                            >
                                                Upload your paid book
                                                <input
                                                    ref={inputPaidRef}
                                                    type="file"
                                                    name='pdfUrl'
                                                    style={{ display: 'none' }}
                                                    accept="application/*"
                                                    onChange={onLoadFile}
                                                />
                                            </Button>
                                            {
                                                selectedFileName.pdfUrl &&
                                                <Typography variant='body2' color='primary' fontWeight='bold' mx={1}>
                                                    {selectedFileName.pdfUrl}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                                        <Button
                                            variant='outlined'
                                            component='label'
                                            startIcon={<UploadFileIcon />}
                                        >
                                            Upload your Free book
                                            <input
                                                ref={inputFreeRef}
                                                type="file"
                                                name='pdfUrl'
                                                style={{ display: 'none' }}
                                                accept="application/*"
                                                onChange={onLoadSample}
                                            />
                                        </Button>
                                        {
                                            selectedFileName.pdfUrl &&
                                            <Typography variant='body2' color='primary' fontWeight='bold' mx={1}>
                                                {selectedFileName.pdfUrl}
                                            </Typography>
                                        }
                                    </Box>

                            }
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                            <Button
                                variant='outlined'
                                color='error'
                                startIcon={<DeleteIcon />}
                                sx={{ color: 'error.main' }}
                                onClick={() => setOpen(true)}
                            >
                                Delete
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<SaveIcon />}
                                onClick={handleSave}
                            >
                                Modify
                            </Button>
                        </Box>
                    </Box>
                </Container >
            }
            {
                (loading || isModified) && <Circular />
            }
            <Alerte open={alerting.open} alerting={alerting.alerting} />
            <Button
                variant='contained'
                color='primary'
                startIcon={<DashboardIcon />}
                href='/Dashboard/allBook'
                sx={{ position: "fixed", top: "20px", left: "20px" }}
            >
                return to your dash
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        هل انت متأكد من الحذف
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Disagree</Button>
                    <Button onClick={handleDelete} autoFocus>
                        نعم
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModifyBook