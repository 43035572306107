import React, { useState, useRef } from 'react'
import { Button, useTheme, useMediaQuery, Box, Typography } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { bookInfos, paidbookprops } from '../../Type/props';
import { v4 as uuidv4 } from 'uuid';
const FilePaid = (props: { bookInfos: bookInfos, setBookInfos: React.Dispatch<React.SetStateAction<bookInfos>> }) => {
    const { bookInfos, setBookInfos } = props
    const [selectedFileName, setSelectedFileName] = useState<paidbookprops>({} as paidbookprops);
    const inputPaidRef = useRef(null);
    const inputSampRef = useRef(null);
    const onLoadFile = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            const uniqueFilename = `${uuidv4()}_${Date.now()}_${files[0].name}`;
            const modifiedFile = new File([files[0]], uniqueFilename, { type: files[0].type });
            setBookInfos({ ...bookInfos, pdfUrl: modifiedFile })
            setSelectedFileName({ ...selectedFileName, pdfUrl: files[0].name })
        }
        event.target.value = '';
    };
    const onLoadSample = (event: any) => {
        const { files } = event.target;
        if (files && files[0]) {
            setBookInfos({ ...bookInfos, pdfRUrl: files[0] })
            setSelectedFileName({ ...selectedFileName, pdfRUrl: files[0].name })
        }
        event.target.value = '';
    };
    const theme = useTheme();
    const isMatchedPhone = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Box sx={{ display: "flex", alignItems: 'center' }}>
                    <Button
                        variant='outlined'
                        component='label'
                        size={isMatchedPhone ? 'medium' : 'large'}
                        startIcon={<UploadFileIcon />}
                    >
                        Upload your sample book
                        <input
                            ref={inputSampRef}
                            type="file"
                            name='pdfRUrl'
                            style={{ display: 'none' }}
                            accept="application/*"
                            onChange={onLoadSample}
                        />
                    </Button>
                    {
                        selectedFileName.pdfRUrl &&
                        <Typography variant='body2' color='primary' mx={1} fontWeight='bold'>
                            {selectedFileName.pdfRUrl}
                        </Typography>
                    }
                </Box>
                <Box sx={{ display: "flex", alignItems: 'center' }}>
                    <Button
                        variant='outlined'
                        component='label'
                        size={isMatchedPhone ? 'small' : 'large'}
                        startIcon={<UploadFileIcon />}
                    >
                        Upload your paid book
                        <input
                            ref={inputPaidRef}
                            type="file"
                            name='pdfUrl'
                            style={{ display: 'none' }}
                            accept="application/*"
                            onChange={onLoadFile}
                        />
                    </Button>
                    {
                        selectedFileName.pdfUrl &&
                        <Typography variant='body2' color='primary' fontWeight='bold' mx={1}>
                            {selectedFileName.pdfUrl}
                        </Typography>
                    }
                </Box>
            </Box>
        </>
    )
}

export default FilePaid