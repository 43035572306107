import { Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';

interface bookProps {
    id: string;
    title: string;
    image: string;
}
const OneBook = (props: bookProps) => {
    const navigate = useNavigate();
    const { id, title, image } = props;
    return (
        <Paper
            key={id}
            sx={{ display: "grid", cursor: "pointer", "&:hover": { bgcolor: "primary.main", color: 'whitesmoke' } }}
            onClick={() => navigate(`/Book/${id}`)}
        >
            <img src={image} alt='book' style={{ width: '220px', aspectRatio: 1 / 1.11 }} />
            <Typography
                variant='h6'
                align='center'
                fontWeight='bold'
                my={1}
            >
                {title}
            </Typography>
        </Paper>
    )
}

export default OneBook