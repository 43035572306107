import { Link } from 'react-router-dom';
import { Typography, Button, Container, Box } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const NotFoundPage = () => {
    return (
        <Container maxWidth="sm">
            <Box textAlign="center" mt={5}>
                <ErrorOutline fontSize="large" color="error" />
                <Typography variant="h4" color="error" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" paragraph>
                    The page you are looking for does not exist.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/">
                    Go Back to Home
                </Button>

            </Box>
        </Container>
    );
};

export default NotFoundPage;
