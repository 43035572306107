import { CircularProgress, Backdrop } from '@mui/material';

const Circular = () => {
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="primary" />
        </Backdrop>
    )
}

export default Circular