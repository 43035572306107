import { Box, Container, Typography } from '@mui/material'
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { PlanProps, Type } from '../../Type/props';

const Plan = (props: PlanProps) => {
    const { type, setType } = props;
    const handleType = (plan: Type) => {
        setType(plan)
    }
    return (
        <>
            <Box sx={{ display: "grid", gap: 3 }}>
                <Typography variant='h4' fontWeight='bold' align='center'>
                    Choose your plan
                </Typography>
                <Container sx={{ display: { xs: 'grid', lg: "flex" }, flexWrap: "wrap", gap: 3 }}>
                    <Box
                        sx={{ display: "flex", flexDirection: 'column', alignItems: "center", p: 5, bgcolor: type === 'free' ? 'primary.main' : "background.paper", '&:hover': { bgcolor: "primary.main" }, borderRadius: '12px', cursor: "pointer" }}
                        onClick={() => handleType('free')}
                    >
                        <MoneyOffIcon />
                        <Typography variant='h5' fontWeight='bolder'>
                            Free plan
                        </Typography>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: 'column', alignItems: "center", p: 5, bgcolor: type === 'paid' ? 'primary.main' : "background.paper", '&:hover': { bgcolor: "primary.main" }, borderRadius: '12px', cursor: "pointer" }}
                        onClick={() => handleType('paid')}
                    >
                        <MonetizationOnRoundedIcon />
                        <Typography variant='h5' fontWeight='bolder' >
                            Paid plan
                        </Typography>
                    </Box>
                </Container >
            </Box >
        </>
    )
}

export default Plan