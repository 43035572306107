import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { Paper, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        paddingLeft: '10px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch'
        },
        [theme.breakpoints.down('xs')]: {
            width: '20ch'
        }
    },
}));

export default function TopBar() {
    return (
        <AppBar
            component={Paper}
            elevation={4}
            position="static"
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    variant='h5'
                    fontWeight='bold'
                >
                    welcome to your Dashboard
                </Typography>

            </Toolbar>
        </AppBar>
    );
}