import { useState, useEffect } from 'react'
import { Container } from '@mui/material';
import OneBook from './OneBook';
import { bookInfos } from '../../Type/props';
import Circular from '../../Global/Circular';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { baseURL2 } from '../Register/Login';
const AllBooks = () => {
    const [books, setBooks] = useState<bookInfos[]>([] as bookInfos[]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        const getbooks = async () => {

            var data = await getDocs(collection(db, "app/data/book"))
                .then((querySnapshot) => {
                    setTimeout(() => {
                        setBooks(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })) as bookInfos[])
                        setLoading(false)
                    }, 1000);
                }).catch((error) => { console.log("Error getting documents: ", error); });


            return;
            // try {
            //     const response = await fetch(`/allBooks`, {
            //         method: 'get'
            //     });
            //     if (response.ok) {
            //         const { books } = await response.json();

            //     }
            // } catch (error) {
            //     console.log(error);

            // }
        }
        getbooks()
    }, [])
    return (
        <>
            {
                !loading &&
                <Container sx={{ my: 2, display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                    {
                        books.map((book, index) => (
                            <OneBook
                                id={book.id}
                                title={book.title}
                                image={((book.image as string).includes("http") ? (book.image as string) : `${baseURL2}/${book.image}`)}
                            />
                        ))
                    }
                </Container>
            }
            {
                loading && <Circular />
            }
        </>
    )
}

export default AllBooks