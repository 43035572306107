import { createTheme } from "@mui/material"

export const Theme = createTheme({
    typography: {
        fontFamily: ['Lato', 'Poppins', 'Inria Sans'].join(),
        button: {
            textTransform: 'none'
        }
    },
    
    palette: {
        primary: {
            main: '#0f4a82',
            light: '#F5F5F5',
            dark: '#1E1E1E',
            A100: '#8B8BA7',
        },
        secondary: {
            main: '#EDEFF2',
            dark: '#EDEFF2'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#0f4a82'
                    }
                },
                outlined: {
                    color: '#1E1E1E',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                },
                text: {
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        }
    }
})