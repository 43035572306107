 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyC3wesPYL1tAFngGm1sbIga8Rw3kwKXJIo",
    authDomain: "menif-book.firebaseapp.com",
    projectId: "menif-book",
    storageBucket: "menif-book.appspot.com",
    messagingSenderId: "21966365322",
    appId: "1:21966365322:web:5815ae08dd7052e5cb0373",
    measurementId: "G-0070PJJYDB",
 };
 // Initialize Firebase
 
 const app = initializeApp(firebaseConfig);
 // Export firestore database
 // It will be imported into your react app whenever it is needed
 export const db = getFirestore(app);