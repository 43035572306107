import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import Dashboard from './Sections/Dash/Dashboard';
import { ThemeProvider } from '@mui/material';
import { Theme } from './Theme/theme';
import Login from './Sections/Register/Login';
import NotFoundPage from './Global/NotFound';
import PrivateRoute from './Utils/PrivateRoute';
import ModifyBook from './Sections/Book/ModifyBook';
import PrivateRegister from './Utils/PrivateRegister';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <BrowserRouter >
          <Routes>
          <Route element={<PrivateRoute />}>
              <Route path='/' element={<Dashboard />} />
            </Route>
            <Route element={<PrivateRegister />}>
              <Route path='/Dashboard/:Selected' element={<Dashboard />} />
              <Route path='/Book/:id' element={<ModifyBook />} />
            </Route>
            
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
